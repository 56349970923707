import { useQuery, useMutation, useQueryClient } from 'react-query';
import { PageInfo } from '../pages/index';
import { apiClient } from './index';

const getAdmins = async ({ queryKey }: any) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  const [_key, { page, pageSize }] = queryKey;
  const query = { page, per_page: pageSize };

  const { data } = await apiClient(true, {
    url: '/admins',
    params: query,
  });

  return data;
};

function useAdmins({ page = 1, pageSize = 20 }: PageInfo) {
  return useQuery(['admins', { page, pageSize }], getAdmins, { keepPreviousData: true });
}

const getAdminById = async ({ queryKey }: any) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  const [_key, { id }] = queryKey;
  const { data } = await apiClient(true, {
    url: `/admin/${id}`,
  });

  return data.data;
};

function useAdminById(id: string) {
  return useQuery(['admins', { id }], getAdminById);
}

const addAdmin = async (params: any) => {
  const { data } = await apiClient(true, {
    method: 'POST',
    url: `/admins`,
    data: params,
  });

  return data.data;
};

function useAddAdmin() {
  const queryClient = useQueryClient();
  return useMutation('addadmin', addAdmin, {
    onSuccess: () => {
      queryClient.invalidateQueries('admins');
    },
  });
}

const updateAdmin = async (params: any) => {
  const { data } = await apiClient(true, {
    method: 'PATCH',
    url: params.url,
    data: params,
  });

  return data.data;
};

function useUpdateAdmin() {
  const queryClient = useQueryClient();
  return useMutation('updateadmin', updateAdmin, {
    onSuccess: () => {
      queryClient.invalidateQueries('admins');
    },
  });
}

const deleteAdmin = async (params: any) => {
  const { data } = await apiClient(true, {
    method: 'DELETE',
    url: `/admin/${params.id}`,
  });

  return data.data;
};

function useDeleteAdmin() {
  const queryClient = useQueryClient();
  return useMutation('deletebranch', deleteAdmin, {
    onSuccess: () => {
      queryClient.invalidateQueries('admins');
    },
  });
}

const changePassword = async (params: any) => {
  const { data } = await apiClient(true, {
    method: 'PATCH',
    url: `/admin/change-password`,
    data: params,
  });

  return data.data;
};

function useChangePassword() {
  const queryClient = useQueryClient();
  return useMutation('changePassword', changePassword, {
    onSuccess: () => {
      queryClient.invalidateQueries('refresh');
    },
  });
}

const getMe = async () => {
  const { data } = await apiClient(true, {
    url: `/me`,
  });

  return data.data;
};

function useMe() {
  return useQuery('me', getMe);
}

const importAdmin = async (file: File) => {
  const form = new FormData();
  form.append('file', file);

  const { data } = await apiClient(true, {
    method: 'POST',
    url: `/admin/import`,
    data: form,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return data.data;
};

function useImportAdmin() {
  const queryClient = useQueryClient();
  return useMutation('importAdmin', importAdmin, {
    onSettled: () => {
      queryClient.invalidateQueries('admins');
    },
  });
}

export {
  useAdminById,
  useAdmins,
  useAddAdmin,
  useUpdateAdmin,
  useDeleteAdmin,
  useChangePassword,
  useMe,
  useImportAdmin,
};
