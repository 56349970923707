import { useQuery, useMutation, useQueryClient } from 'react-query';
import { apiClient } from './index';

interface NcPageInterface {
  page: number;
  pageSize?: number;
  type: string;
}

const getNcCount = async () => {
  const { data } = await apiClient(true, {
    url: `/notifications/count`,
  });

  return data.data;
};

function useNCCount() {
  return useQuery('notifications', getNcCount, {
    refetchInterval: 30 * 1000,
    refetchOnWindowFocus: true,
  });
}

const getNotifications = async ({ queryKey }: any) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  const [_key, { page, pageSize, type }] = queryKey;
  const { data } = await apiClient(true, {
    url: `/notifications/${type}?page=${page}&per_page=${pageSize}`,
  });

  return data;
};

function useNotifications({ page, pageSize, type }: NcPageInterface) {
  return useQuery(['notifications', { page, pageSize, type }], getNotifications, {
    refetchOnReconnect: true,
    staleTime: 0,
  });
}

const changeNcStatus = async (params: any) => {
  const { data } = await apiClient(true, {
    method: 'PATCH',
    url: params.url,
  });

  return data.data;
};

function useChangeNcStatus() {
  const queryClient = useQueryClient();
  return useMutation('statuscustomer', changeNcStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries('notifications');
    },
  });
}

export { useNCCount, useNotifications, useChangeNcStatus };
