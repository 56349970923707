import { ReloadOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React, { FC } from 'react';
import { ButtonProps, IButtonProps } from '../App/Forms/FormComponent';

export const RefreshButton: FC<IButtonProps> = ({
  htmlType,
  label,
  onClick,
  style,
  icon,
  size,
  shape,
  type,
  disabled,
  hidden,
  loading,
}: IButtonProps) => (
  <Tooltip title="Refresh" placement="right">
    <Button
      style={{ ...style }}
      size={size}
      shape={shape}
      icon={icon}
      type={type}
      htmlType={htmlType}
      onClick={onClick}
      className="update-form-button"
      disabled={disabled}
      hidden={hidden}
      loading={loading}
    >
      {label}
    </Button>
  </Tooltip>
);

RefreshButton.defaultProps = {
  size: 'small',
  shape: ButtonProps.shape,
  htmlType: 'button',
  type: 'default',
  label: undefined,
  icon: <ReloadOutlined />,
  style: {},
  onClick: () => null,
  disabled: false,
  hidden: false,
  loading: false,
};
