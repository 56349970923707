const APP_NAME = 'APBS Gateway';
const THEME_DARK = 'dark';
const APP_COLOR = 'green';
const HORIZONTAL = 'horizontal';
const VERTICAL = 'vertical';
const BaseURL = process.env.REACT_APP_DOMAIN;
const FileDownloadURL = `${BaseURL}file/sample/`;
const MicrosoftExcelType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const OpenExcelType = 'application/vnd.ms-excel';
const ExcelFiles = [MicrosoftExcelType, OpenExcelType].join(', ');
const AuthStorageKey = 'Gateway_Token';

export {
  APP_NAME,
  THEME_DARK,
  APP_COLOR,
  HORIZONTAL,
  VERTICAL,
  BaseURL,
  FileDownloadURL,
  ExcelFiles,
  MicrosoftExcelType,
  OpenExcelType,
  AuthStorageKey,
};
