import {
  HomeOutlined,
  AppstoreOutlined,
  UserOutlined,
  GlobalOutlined,
  MonitorOutlined,
  SettingOutlined,
  WindowsOutlined,
  ApiOutlined,
  ClusterOutlined,
  DashboardOutlined,
  FileTextOutlined,
  BankOutlined,
  ContactsOutlined,
  BranchesOutlined,
  GroupOutlined,
  StepBackwardOutlined,
  ControlOutlined,
  ProfileOutlined,
} from '@ant-design/icons';
import { Divider, Menu, MenuTheme, Typography } from 'antd';
import React, { FC } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAppById } from '../../api/apps';
import { usePermission } from '../Permissions/PermissionProvider';

export interface MenuProps {
  onSelectHandler: (e: any) => void;
  theme: MenuTheme;
  style: any;
  defaultSelected: [string];
}

export interface IAppMenuProps extends MenuProps {
  uid: string;
}

interface HomeButtonInterface {
  path: string;
  menuLabel: string;
}

const BackToHome = ({ path, menuLabel }: HomeButtonInterface) => {
  return (
    <>
      <Menu.Item key={path} icon={<StepBackwardOutlined />}>
        <Link to={path}>{menuLabel}</Link>
      </Menu.Item>
    </>
  );
};

interface IModuleProps {
  menuLabel: string | undefined;
}

const ModuleIdentity = ({ menuLabel }: IModuleProps) => {
  const { Title } = Typography;

  return (
    <>
      <Title
        level={5}
        style={{
          margin: '20px',
        }}
      >
        {menuLabel || 'App'}
      </Title>
      <Divider />
    </>
  );
};

const MainMenu: FC<MenuProps> = ({ onSelectHandler, theme, style, defaultSelected }: MenuProps) => {
  const { checkPermit } = usePermission();

  return (
    <Menu
      theme={theme}
      style={style}
      defaultSelectedKeys={defaultSelected}
      onClick={onSelectHandler}
    >
      <Menu.Item key="home" icon={<HomeOutlined />}>
        <Link to="/home">Home</Link>
      </Menu.Item>
      <Menu.Item key="apps" icon={<AppstoreOutlined />} disabled={!checkPermit('app-list')}>
        <Link to="/apps">Apps</Link>
      </Menu.Item>
      <Menu.Item key="customer" icon={<UserOutlined />} disabled={!checkPermit('customer-list')}>
        <Link to="/customer">Customers</Link>
      </Menu.Item>
      <Menu.Item key="business" icon={<GlobalOutlined />} disabled={!checkPermit('business-list')}>
        <Link to="/business">Business</Link>
      </Menu.Item>
      <Menu.Item key="monitoring" icon={<MonitorOutlined />}>
        <Link to="/monitoring">Monitoring</Link>
      </Menu.Item>
      <Menu.Item key="settings" icon={<SettingOutlined />}>
        <Link to="/settings">Settings</Link>
      </Menu.Item>
    </Menu>
  );
};

const AppsMenu: FC<IAppMenuProps> = ({
  onSelectHandler,
  theme,
  style,
  defaultSelected,
  uid,
}: IAppMenuProps) => {
  const appData = useAppById(uid);
  const { checkPermit } = usePermission();

  return (
    <Menu
      theme={theme}
      style={style}
      defaultSelectedKeys={defaultSelected}
      onClick={onSelectHandler}
      mode="inline"
    >
      <ModuleIdentity menuLabel={appData?.data?.name} />
      <Menu.Item
        key="dashboard"
        icon={<DashboardOutlined />}
        disabled={!checkPermit('app-dashboard-stats')}
      >
        <Link to={`/app/${uid}/dashboard`}>Dashboard</Link>
      </Menu.Item>
      <Menu.Item key="overview" icon={<WindowsOutlined />} disabled={!checkPermit('app-detail')}>
        <Link to={`/app/${uid}/overview`}>Overview</Link>
      </Menu.Item>
      <Menu.Item key="service" icon={<ApiOutlined />} disabled={!checkPermit('app-service-detail')}>
        <Link to={`/app/${uid}/services`}>Services</Link>
      </Menu.Item>
      <Menu.Item key="products" icon={<ClusterOutlined />} disabled={!checkPermit('product-list')}>
        <Link to={`/app/${uid}/products`}>Products</Link>
      </Menu.Item>
      <Menu.Item
        key="customer"
        icon={<UserOutlined />}
        disabled={!checkPermit('app-customer-list')}
      >
        <Link to={`/app/${uid}/customer`}>Customer</Link>
      </Menu.Item>
      <Menu.Item
        key="business"
        icon={<GlobalOutlined />}
        disabled={!checkPermit('app-business-list')}
      >
        <Link to={`/app/${uid}/business`}>Business</Link>
      </Menu.Item>
      <Menu.SubMenu title="Monitoring" key="monitoring" icon={<MonitorOutlined />}>
        <Menu.Item key="transaction-requests">
          <Link to={`/app/${uid}/transaction-requests`}>Service Request</Link>
        </Menu.Item>
        <Menu.Item key="transactions">
          <Link to={`/app/${uid}/transactions`}>Transactions</Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key="apps" icon={<StepBackwardOutlined />}>
        <Link to="/apps">Home</Link>
      </Menu.Item>
    </Menu>
  );
};

const MonitorMenu: FC<MenuProps> = ({
  onSelectHandler,
  theme,
  style,
  defaultSelected,
}: MenuProps) => {
  const { checkPermit } = usePermission();

  return (
    <Menu
      theme={theme}
      style={style}
      defaultSelectedKeys={defaultSelected}
      onClick={onSelectHandler}
    >
      <ModuleIdentity menuLabel="Monitoring" />
      <Menu.Item
        key="service-request"
        icon={<FileTextOutlined />}
        disabled={!checkPermit('transaction-request-list')}
      >
        <Link to="/monitoring/">Service Requests</Link>
      </Menu.Item>
      <Menu.Item
        key="transactions"
        icon={<FileTextOutlined />}
        disabled={!checkPermit('transaction-list')}
      >
        <Link to="/monitoring/transactions">Transactions</Link>
      </Menu.Item>
      <Menu.Item key="activity-log" icon={<FileTextOutlined />}>
        <Link to="/monitoring/activity-log">Activity Log</Link>
      </Menu.Item>
      <BackToHome path="/" menuLabel="Home" />
    </Menu>
  );
};

const SettingsMenu: FC<MenuProps> = ({
  onSelectHandler,
  theme,
  style,
  defaultSelected,
}: MenuProps) => {
  const { checkPermit } = usePermission();

  return (
    <Menu
      theme={theme}
      style={style}
      defaultSelectedKeys={defaultSelected}
      onClick={onSelectHandler}
    >
      <ModuleIdentity menuLabel="Settings" />
      <Menu.Item key="admin" icon={<GroupOutlined />} disabled={!checkPermit('admin-list')}>
        <Link to="/settings/">Admin</Link>
      </Menu.Item>
      <Menu.Item key="roles" icon={<ContactsOutlined />} disabled={!checkPermit('role-list')}>
        <Link to="/settings/roles">Roles</Link>
      </Menu.Item>
      <Menu.Item key="branch" icon={<BranchesOutlined />} disabled={!checkPermit('branch-list')}>
        <Link to="/settings/branch">Bank Branches</Link>
      </Menu.Item>
      <Menu.Item key="bank-info" icon={<BankOutlined />}>
        <Link to="/settings/bank-info">Bank Info</Link>
      </Menu.Item>
      <Menu.Item key="system" icon={<ProfileOutlined />}>
        <Link to="/settings/system">System</Link>
      </Menu.Item>
      <Menu.Item key="profiles" icon={<ControlOutlined />} disabled={!checkPermit('profile-list')}>
        <Link to="/settings/profiles">User Profiles</Link>
      </Menu.Item>

      <BackToHome path="/" menuLabel="Home" />
    </Menu>
  );
};

interface INavBarProps {
  style: any;
  theme: MenuTheme;
}

export const NavBar: FC<INavBarProps> = ({ style, theme }: INavBarProps) => {
  const history = useHistory();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRoutes = (e: any) => {
    history.push(e.key);
  };

  const path = location.pathname.split('/');

  switch (path[1]) {
    case 'app':
      return (
        <AppsMenu
          theme={theme}
          style={style}
          defaultSelected={[path[3] || 'overview']}
          onSelectHandler={handleRoutes}
          uid={path[2]}
        />
      );

    case 'monitoring':
      return (
        <MonitorMenu
          theme={theme}
          style={style}
          defaultSelected={[path[2] || 'service-request']}
          onSelectHandler={handleRoutes}
        />
      );

    case 'settings':
      return (
        <SettingsMenu
          theme={theme}
          style={style}
          defaultSelected={[path[2] || 'admin']}
          onSelectHandler={handleRoutes}
        />
      );

    case 'login':
      return <div> </div>;

    default:
      return (
        <MainMenu
          theme={theme}
          style={style}
          defaultSelected={[path[1] || 'home']}
          onSelectHandler={handleRoutes}
        />
      );
  }
};
