import { useQuery, useMutation, useQueryClient } from 'react-query';
import { apiClient } from './index';

const getBranches = async () => {
  const { data } = await apiClient(true, {
    url: `/branchs`,
  });

  return data.data;
};

function useBranches() {
  return useQuery('branches', getBranches, {
    staleTime: 1000,
  });
}

const addBranch = async (params: any) => {
  const { data } = await apiClient(true, {
    method: 'POST',
    url: `/branchs`,
    data: params,
  });

  return data.data;
};

function useAddBranch() {
  const queryClient = useQueryClient();
  return useMutation('addbranch', addBranch, {
    onSuccess: () => {
      queryClient.invalidateQueries('branches');
    },
  });
}

const updateBranch = async (params: any) => {
  const { data } = await apiClient(true, {
    method: 'PATCH',
    url: `/branch/${params.id}`,
    data: params,
  });

  return data.data;
};

function useUpdateBranch() {
  const queryClient = useQueryClient();
  return useMutation('updatebranch', updateBranch, {
    onSuccess: () => {
      queryClient.invalidateQueries('branches');
    },
  });
}

const deleteBranch = async (params: any) => {
  const { data } = await apiClient(true, {
    method: 'DELETE',
    url: `/branch/${params.id}`,
  });

  return data.data;
};

function useDeleteBranch() {
  const queryClient = useQueryClient();
  return useMutation('deletebranch', deleteBranch, {
    onSuccess: () => {
      queryClient.invalidateQueries('branches');
    },
  });
}

const importBranch = async (file: File) => {
  const form = new FormData();
  form.append('file', file);

  const { data } = await apiClient(true, {
    method: 'POST',
    url: `/branch/import`,
    data: form,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return data.data;
};

function useImportBranch() {
  const queryClient = useQueryClient();
  return useMutation('importBranch', importBranch, {
    onSettled: () => {
      queryClient.invalidateQueries('branches');
    },
  });
}

export { useBranches, useAddBranch, useDeleteBranch, useUpdateBranch, useImportBranch };
