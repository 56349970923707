import { BellOutlined, FileExcelOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, Space, Dropdown, Menu, Row, Col } from 'antd';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMe } from '../../api/admin';
import { useLogout } from '../../api/auth';
import { useBankLogo, useBankInfo } from '../../api/bankinfo';
import { useNCCount } from '../../api/notification';
import { HORIZONTAL } from '../../common/constants';
import classes from './Header.module.scss';
import { SampleFilesModal } from './ImportFiles';
import { NoitfyDrawer } from './Notification';
import { UserProfileModal } from './UserProfile';

export interface NotificationProps {
  count?: number;
  classname?: string;
}

export const HeaderBanner: FC = () => {
  const { data } = useMe();
  const bankLogo = useBankLogo();
  const bankInfo = useBankInfo();
  const countInfo = useNCCount();
  const logout = useLogout();
  const history = useHistory();

  const [isNcVisible, setNcVisible] = useState<boolean>(false);
  const [isProfileVisible, setProfileVisible] = useState<boolean>(false);
  const [isSampleFilesVisible, setSampleFilesVisible] = useState<boolean>(false);

  const onLogout = () => {
    logout.mutate();
  };

  const menu = (
    <Menu>
      <Menu.Item icon={<UserOutlined />} onClick={() => setProfileVisible(true)} key="profile">
        User Profile
      </Menu.Item>
      <Menu.Item icon={<LogoutOutlined />} onClick={() => onLogout()} key="logout">
        LogOut
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Row className={classes.header}>
        <Col offset={0} span={6} style={{ maxHeight: '50px' }}>
          {bankInfo.data && (
            <Button
              onClick={() => history.push('/')}
              type="link"
              style={{ color: bankInfo?.data?.color_code, fontWeight: 'bold', fontSize: '18px' }}
              icon={
                <img
                  src={`data:image/jpeg;base64,${bankLogo.data}`}
                  alt="BankLogo"
                  style={{ width: '30px', height: '30px' }}
                />
              }
            >
              &nbsp; &nbsp; {bankInfo?.data?.name}
            </Button>
          )}
        </Col>
        <Col span={6} className={classes.avatar}>
          <Space direction={HORIZONTAL}>
            <Badge count={countInfo.data?.count}>
              <Button
                icon={<BellOutlined />}
                shape="circle"
                size="small"
                onClick={() => setNcVisible(true)}
              />
            </Badge>
            <span style={{ color: bankInfo?.data?.color_code, fontSize: '16px' }}>
              {data?.username}
            </span>
            <Dropdown overlay={menu}>
              <Avatar shape="circle" size="small" icon={<UserOutlined />} />
            </Dropdown>
            <Button
              icon={<FileExcelOutlined />}
              shape="circle"
              size="small"
              onClick={() => setSampleFilesVisible(true)}
            />
          </Space>
        </Col>
      </Row>

      <NoitfyDrawer visible={isNcVisible} setVisible={setNcVisible} />
      <UserProfileModal isVisible={isProfileVisible} setVisible={setProfileVisible} />
      <SampleFilesModal visible={isSampleFilesVisible} setVisible={setSampleFilesVisible} />
    </>
  );
};
