import { useQuery, useMutation, useQueryClient, useQueries } from 'react-query';
import { apiClient } from './index';

const getTariffAccount = async () => {
  const { data } = await apiClient(true, {
    url: `/settings/tariff-account/`,
  });

  return data.data;
};

function useTariffAccount() {
  return useQuery('tariffAccount', getTariffAccount, {
    refetchOnReconnect: true,
    staleTime: 1000,
  });
}

const getBankInfo = async () => {
  const { data } = await apiClient(true, {
    url: `/settings/bank-info`,
  });

  return data.data;
};

function useBankInfo() {
  return useQuery('bankInfo', getBankInfo, {
    staleTime: Infinity,
  });
}

const addTariffAccount = async (params: any) => {
  const { data } = await apiClient(true, {
    method: 'POST',
    url: `/settings/tariff-account/`,
    data: params,
  });

  return data.data;
};

function useAddTariffAccount() {
  const queryClient = useQueryClient();
  return useMutation('addTariffAccount', addTariffAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries('tariffAccount');
    },
  });
}

const addBankInfo = async (params: any) => {
  const { data } = await apiClient(true, {
    method: 'POST',
    url: `/settings/bank-info`,
    data: params,
  });

  return data.data;
};

function useAddBankInfo() {
  const queryClient = useQueryClient();
  return useMutation('addBankInfo', addBankInfo, {
    onSuccess: () => {
      queryClient.invalidateQueries('bankInfo');
    },
  });
}

const updateTariffAccount = async (params: any) => {
  const { data } = await apiClient(true, {
    method: 'PATCH',
    url: `/settings/tariff-account/`,
    data: params,
  });

  return data.data;
};

function useUpdateTariffAccount() {
  const queryClient = useQueryClient();
  return useMutation('updateTariffAccount', updateTariffAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries('tariffAccount');
    },
  });
}

const updateBankInfo = async (params: any) => {
  const { data } = await apiClient(true, {
    method: 'PATCH',
    url: `/settings/bank-info`,
    data: params,
  });

  return data.data;
};

function useUpdateBankInfo() {
  const queryClient = useQueryClient();
  return useMutation('updateBankInfo', updateBankInfo, {
    onSuccess: () => {
      queryClient.invalidateQueries('bankInfo');
    },
  });
}

const getBankLogo = async () => {
  const { data } = await apiClient(false, {
    url: `/bank-logo/`,
    responseType: 'arraybuffer',
  });

  return Buffer.from(data, 'binary').toString('base64');
};

function useBankLogo() {
  return useQuery('bankLogo', getBankLogo);
}

const importLogo = async (file: File) => {
  const form = new FormData();
  form.append('logo', file);

  const { data } = await apiClient(true, {
    method: 'POST',
    url: `/bank-logo/`,
    data: form,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return data.data;
};

function useImportLogo() {
  const queryClient = useQueryClient();
  return useMutation('importBranch', importLogo, {
    onSettled: () => {
      queryClient.invalidateQueries('bankLogo');
    },
  });
}

function useBankSettings() {
  return useQueries([
    { queryKey: 'bankLogo', queryFn: getBankLogo },
    { queryKey: 'bankInfo', queryFn: getBankInfo },
  ]);
}

export {
  useAddBankInfo,
  useAddTariffAccount,
  useBankInfo,
  useTariffAccount,
  useUpdateBankInfo,
  useUpdateTariffAccount,
  useBankLogo,
  useImportLogo,
  useBankSettings,
};
