import { useMutation } from 'react-query';
import { createAuthProvider } from 'react-token-auth';
import { AuthStorageKey } from '../common/constants';
import { queryClient } from './base';
import { apiClient } from './index';

export interface TokenInterface {
  access_token: string;
  refresh_token: string;
}

interface LoginInterface {
  username: string;
  password: string;
}

interface ILoginVerify {
  url: string;
  otp: string;
}

const authenticate = async (params: LoginInterface) => {
  const { data } = await apiClient(false, {
    url: `/login`,
    method: 'POST',
    data: params,
  });

  return data;
};

const refresh = async (token: TokenInterface) => {
  const { data } = await apiClient(false, {
    url: `/refresh`,
    method: 'POST',
    headers: { Authorization: `Bearer ${token.refresh_token}` },
  });

  return data.data;
};

const revoke = async () => {
  const { data } = await apiClient(true, {
    url: `/logout`,
    method: 'POST',
  });

  return data;
};

const { useAuth, authFetch, login, logout } = createAuthProvider({
  getAccessToken: (session) => session.access_token,
  storage: localStorage,
  storageKey: AuthStorageKey,
  onUpdateToken: (token: TokenInterface) => refresh(token),
});

function useLogin() {
  return useMutation('login', authenticate);
}

function useLogout() {
  return useMutation('revoke', revoke, {
    onSuccess: () => {
      queryClient.invalidateQueries();
      logout();
    },
  });
}

const verifyLogin = async (params: ILoginVerify) => {
  const { url, otp } = params;
  const { data } = await apiClient(true, {
    url,
    method: 'POST',
    data: { otp },
  });

  return data;
};

function useLoginVerify() {
  return useMutation('verifylogin', verifyLogin);
}

export { useAuth, authFetch, useLogin, useLogout, login, useLoginVerify };
