import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { lazyComponent } from '../../common/utils';
import { Branch } from '../../pages/Settings/Branch';

const Home = lazyComponent('Home', import('../../pages/Home/Home'));
const Apps = lazyComponent('Apps', import('../../pages/Apps/Apps'));
const AppDashboard = lazyComponent('AppDashboard', import('../../pages/Apps/AppDashboard'));
const AppOverview = lazyComponent('AppDetails', import('../../pages/Apps/AppDetails'));
const AppCustomers = lazyComponent('AppCustomer', import('../../pages/Apps/AppCustomers'));
const AppBusiness = lazyComponent('AppBusiness', import('../../pages/Apps/AppBusiness'));
const AppServices = lazyComponent('AppServices', import('../../pages/Apps/AppServices'));
const AppProducts = lazyComponent('AppProducts', import('../../pages/Apps/AppProducts'));
const ServiceInfo = lazyComponent('ServiceDetails', import('../../pages/Apps/ServiceDetails'));
const ProductInfo = lazyComponent('ProductDetails', import('../../pages/Apps/ProductDetails'));
const Customer = lazyComponent('Customer', import('../../pages/Customer/Customer'));
const CustomerDetails = lazyComponent(
  'CustomerDetails',
  import('../../pages/Customer/CustomerDetails')
);
const Business = lazyComponent('Business', import('../../pages/Business/Business'));
const BusinessDetails = lazyComponent(
  'BusinessDetails',
  import('../../pages/Business/BusinessDetails')
);
const Monitoring = lazyComponent('Monitoring', import('../../pages/Monitoring/Monitoring'));
const TransactionRequest = lazyComponent(
  'TransactionRequest',
  import('../../pages/Monitoring/TransactionRequest')
);
const Transaction = lazyComponent('Transaction', import('../../pages/Monitoring/Transactions'));
const ActivityLog = lazyComponent('ActivityLog', import('../../pages/Monitoring/ActivityLog'));
const Admin = lazyComponent('Admin', import('../../pages/Settings/Settings'));
const Roles = lazyComponent('Roles', import('../../pages/Settings/Roles'));
const Permissions = lazyComponent('RoleDetails', import('../../pages/Settings/Permissions'));
const BankInfo = lazyComponent('BankInfo', import('../../pages/Settings/BankInfo'));
const SystemInfo = lazyComponent('SystemInfo', import('../../pages/Settings/System'));
const ProfileInfo = lazyComponent('UserProfiles', import('../../pages/Settings/Profile'));
const PageNotFound = lazyComponent('PageNotFound', import('../../pages/PageNotFound/PageNotFound'));

export const Routes: FC = () => (
  <Switch>
    <Route component={Home} exact path="/home" />
    <Route component={Apps} exact path="/apps" />
    <Route component={AppDashboard} exact path="/app/:uid/dashboard" />
    <Route component={AppServices} exact path="/app/:uid/service" />
    <Route component={ServiceInfo} exact path="/app/:appid/service/:uid" />
    <Route component={AppProducts} exact path="/app/:uid/products" />
    <Route component={ProductInfo} exact path="/app/:appid/product/:uid" />
    <Route component={AppCustomers} exact path="/app/:uid/customer" />
    <Route component={AppBusiness} exact path="/app/:uid/business" />
    <Route component={Monitoring} exact path="/app/:appid/monitoring" />
    <Route component={Monitoring} exact path="/app/:appid/transaction-requests" />
    <Route component={TransactionRequest} exact path="/app/:appid/transaction-requests/:uid" />
    <Route component={Transaction} exact path="/app/:appid/transactions" />
    <Route component={AppOverview} path="/app/:uid" />
    <Route component={Customer} exact path="/customer" />
    <Route component={CustomerDetails} exact path="/customer/:uid" />
    <Route component={Business} exact path="/business" />
    <Route component={BusinessDetails} exact path="/business/:uid" />
    <Route component={TransactionRequest} exact path="/monitoring/transaction-request/:uid" />
    <Route component={Transaction} exact path="/monitoring/transactions" />
    <Route component={ActivityLog} exact path="/monitoring/activity-log" />
    <Route component={Monitoring} path="/monitoring" />
    <Route component={Admin} exact path="/settings/admin" />
    <Route component={Roles} exact path="/settings/roles" />
    <Route component={Permissions} exact path="/settings/roles/:uid" />
    <Route component={Branch} exact path="/settings/branch" />
    <Route component={BankInfo} exact path="/settings/bank-info" />
    <Route component={SystemInfo} exact path="/settings/system" />
    <Route component={ProfileInfo} exact path="/settings/profiles" />
    <Route component={Admin} path="/settings/" />
    <Route component={Home} exact path="/" />
    <Route component={PageNotFound} />
  </Switch>
);
