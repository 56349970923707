import { ConfigProvider } from 'antd';
import React, { FC } from 'react';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { useAuth } from '../../api/auth';
import { useBankInfo } from '../../api/bankinfo';
import { queryClient } from '../../api/base';
import { Login } from '../../pages/Login/Login';
import { PageLayout } from '../Layout/Layout';
import { PermissionReference } from '../Permissions/PermissionProvider';
import { Routes } from '../Routes/Routes';
import { FormConfig } from './GlobalConfig';

const GatewayPages: FC = () => {
  const bankInfo = useBankInfo();

  if (bankInfo.isSuccess) {
    try {
      window.less.modifyVars({
        '@primary-color': bankInfo?.data.color_code,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }

  return (
    <PermissionReference>
      <PageLayout theme="light">
        <Routes />
      </PageLayout>
    </PermissionReference>
  );
};

const AuthenticatedApp: FC = () => {
  const [logged] = useAuth();

  if (logged) {
    return <GatewayPages />;
  }

  return <Login />;
};

const App: FC = () => (
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <ConfigProvider form={FormConfig}>
        <AuthenticatedApp />
      </ConfigProvider>
    </BrowserRouter>
  </QueryClientProvider>
);

export { App };
