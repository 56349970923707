import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ButtonHTMLType, ButtonShape, ButtonType } from 'antd/lib/button/button';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import React, { CSSProperties, FC } from 'react';

const CancelButtonProps = { color: 'red' };
const SubmitButtonProps = { color: 'green' };
const ButtonProps = { shape: undefined, type: 'default' };

export interface IButtonProps {
  htmlType?: ButtonHTMLType;
  onClick?: () => void;
  label?: string | undefined;
  style?: CSSProperties;
  icon?: React.ReactNode;
  size?: SizeType;
  shape?: ButtonShape;
  type?: ButtonType;
  disabled?: boolean;
  loading?: boolean;
  hidden?: boolean;
}

const SubmitButton: FC<IButtonProps> = ({
  htmlType,
  label,
  onClick,
  style,
  icon,
  size,
  shape,
  type,
  disabled,
  hidden,
  loading,
}: IButtonProps) => (
  <Button
    style={{ ...SubmitButtonProps, ...style }}
    size={size}
    shape={shape}
    icon={icon}
    type={type}
    htmlType={htmlType}
    onClick={onClick}
    className="update-form-button"
    disabled={disabled}
    hidden={hidden}
    loading={loading}
  >
    {label}
  </Button>
);

const CancelButton: FC<IButtonProps> = ({
  htmlType,
  label,
  onClick,
  style,
  icon,
  size,
  shape,
  type,
  disabled,
  loading,
  hidden,
}: IButtonProps) => {
  return (
    <Button
      style={{ ...CancelButtonProps, ...style }}
      size={size}
      shape={shape}
      icon={icon}
      type={type}
      htmlType={htmlType}
      className="cancel-form-button"
      onClick={onClick}
      disabled={disabled}
      hidden={hidden}
      loading={loading}
    >
      {label}
    </Button>
  );
};

SubmitButton.defaultProps = {
  size: 'small',
  shape: ButtonProps.shape,
  htmlType: 'submit',
  type: 'default',
  label: 'Save',
  icon: <CheckOutlined />,
  style: {},
  onClick: () => null,
  disabled: false,
  hidden: false,
  loading: false,
};

CancelButton.defaultProps = {
  size: 'small',
  shape: ButtonProps.shape,
  type: 'default',
  htmlType: 'button',
  label: 'Cancel',
  icon: <CloseOutlined />,
  style: {},
  onClick: () => null,
  disabled: false,
  hidden: false,
  loading: false,
};

export { CancelButton, SubmitButton, CancelButtonProps, SubmitButtonProps, ButtonProps };
