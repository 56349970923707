import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Modal, Form, Input, Button, message, Space, Tabs, Descriptions } from 'antd';
import React, { FC, useState } from 'react';
import { useChangePassword, useMe } from '../../api/admin';
import { IPassswordEditError } from './index';

const { TabPane } = Tabs;

interface UserProfileInterface {
  setVisible: (status: boolean) => void;
  isVisible: boolean;
}

export const UserProfileModal: FC<UserProfileInterface> = ({
  isVisible,
  setVisible,
}: UserProfileInterface) => {
  const [validationError, setValidation] = useState<IPassswordEditError>({});
  const updateService = useChangePassword();
  const { data, isLoading, isError } = useMe();

  if (isLoading) {
    return <p />;
  }

  if (isError) {
    return <p />;
  }

  const onSubmit = (values: any) => {
    updateService.mutate(values, {
      onSuccess: () => {
        message.success('Password Changed Successfully');
        setVisible(false);
      },
      onError: (error: any) => {
        setValidation(error.response.data.error);
      },
    });
  };

  const resetStates = () => {
    setValidation({});
  };

  const UserInfo = () => {
    return (
      <Descriptions title="User Details" layout="horizontal" bordered column={1}>
        <Descriptions.Item label="Username" span={1}>
          {data.username}
        </Descriptions.Item>
        <Descriptions.Item label="First Name" span={1}>
          {data.first_name}
        </Descriptions.Item>
        <Descriptions.Item label="Last Name" span={1}>
          {data.last_name}
        </Descriptions.Item>
        <Descriptions.Item label="Email Address" span={1}>
          {data.email}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  return (
    <Modal
      title="User Profile"
      centered
      visible={isVisible}
      width={500}
      style={{ maxHeight: 500 }}
      onCancel={() => setVisible(false)}
      maskClosable={false}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      destroyOnClose
    >
      <Tabs defaultActiveKey="1" destroyInactiveTabPane>
        <TabPane tab="User Profile" key="1">
          <UserInfo />
        </TabPane>
        <TabPane tab="Change Password" key="2">
          <Form
            name="passwordfroms"
            className="editform"
            onFinish={onSubmit}
            onChange={resetStates}
            size="small"
            preserve={false}
            layout="vertical"
          >
            <Form.Item
              name="current_password"
              label="Current Password"
              rules={[{ required: true }]}
              validateStatus={validationError.current_password ? 'error' : undefined}
              help={validationError.current_password && validationError.current_password[0]}
            >
              <Input.Password
                placeholder="Current Password"
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label="New Password"
              rules={[{ required: true }]}
              validateStatus={validationError.password ? 'error' : undefined}
              help={validationError.password}
            >
              <Input.Password
                placeholder="New Password"
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              label="Confirm Password"
              rules={[{ required: true }]}
              validateStatus={validationError.confirm_password ? 'error' : undefined}
              help={validationError.confirm_password && validationError.confirm_password[0]}
            >
              <Input.Password
                placeholder="Confirm Password"
                type="text"
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <Form.Item
              name="otp"
              label="OTP"
              rules={[{ required: data && data?.otp_enabled }]}
              validateStatus={validationError.otp ? 'error' : undefined}
              help={validationError.otp && validationError.otp[0]}
              hidden={!(data && data?.otp_enabled)}
            >
              <Input placeholder="OTP" />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit" className="update-form-button">
                  Update
                </Button>
                <Button
                  type="primary"
                  htmlType="button"
                  className="cancel-form-button"
                  onClick={() => setVisible(false)}
                >
                  Cancel
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </TabPane>
      </Tabs>
    </Modal>
  );
};
