import { CloudUploadOutlined, MinusOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  List,
  Skeleton,
  Typography,
  message,
  Tooltip,
  Modal,
  Form,
  Input,
  Space,
  Upload,
} from 'antd';
import React, { FC, useState } from 'react';
import {
  useBranches,
  useDeleteBranch,
  useUpdateBranch,
  useAddBranch,
  useImportBranch,
} from '../../api/branch';
import { ExcelFiles } from '../../common/constants';
import { CancelButton, SubmitButton } from '../../components/App/Forms/FormComponent';
import { Page } from '../../components/Page/Page';
import { RefreshButton } from '../../components/Page/Refresh';
import { usePermission } from '../../components/Permissions/PermissionProvider';

const { Title, Text } = Typography;

interface IAddBranch {
  isVisible: boolean;
  setVisible: () => void;
}
const AddBranchModel: FC<IAddBranch> = ({ isVisible, setVisible }: IAddBranch) => {
  const addHandle = useAddBranch();
  const [validationError, setValidationError] = useState<any>(undefined);

  const resetValidation = () => {
    setValidationError(undefined);
  };

  const addBranch = (values: any) => {
    addHandle.mutate(values, {
      onSuccess: () => {
        setVisible();
        message.success('Added branch successfully');
      },

      onError: (error: any) => {
        setValidationError(error.response.data.error);
      },
    });
  };

  const resetModal = () => {
    resetValidation();
    setVisible();
  };

  return (
    <Modal
      title="Create Branch"
      centered
      visible={isVisible}
      width={300}
      onCancel={resetModal}
      maskClosable={false}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      destroyOnClose
    >
      <Form
        name="addBranches"
        className="branch-add-form"
        onFinish={addBranch}
        size="small"
        preserve={false}
        layout="vertical"
        onChange={resetValidation}
      >
        <Form.Item
          name="name"
          label="Branch Name"
          rules={[{ required: true }]}
          validateStatus={validationError ? 'error' : undefined}
          help={validationError?.name}
        >
          <Input placeholder="Branch Name" type="text" />
        </Form.Item>
        <Form.Item>
          <Space>
            <SubmitButton label="Create" loading={addHandle.isLoading} />
            <CancelButton onClick={resetModal} />
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const Branch: FC = () => {
  const { checkPermit } = usePermission();
  const branches = useBranches();
  const { data, isLoading, isError, refetch } = branches;
  const editHandle = useUpdateBranch();
  const deleteHandle = useDeleteBranch();
  const [isAddMode, setAddMode] = useState(false);
  const importBranch = useImportBranch();

  if (isLoading) {
    return <Skeleton paragraph />;
  }

  if (isError) {
    return null;
  }

  const updateBranch = (name: string, id: string) => {
    editHandle.mutate(
      { id, name },
      {
        onSuccess: () => {
          message.success('Updated branch successfully');
        },

        onError: (error: any) => {
          message.error(error.response.data.error.name[0] || error.response.data.error);
        },
      }
    );
  };

  const deleteBranch = (id: string) => {
    deleteHandle.mutate(
      { id },
      {
        onSuccess: () => {
          message.success('Deleted branch successfully');
        },

        onError: (error: any) => {
          message.error(error.response.data.error);
        },
      }
    );
  };

  const onFileUpload = (file: File) => {
    importBranch.mutate(file, {
      onSuccess: () => {
        message.success('Imported branch successfully');
      },

      onError: (error: any) => {
        message.error(error.response.data.error);
      },
    });
  };

  const BranchList: FC = () => {
    return (
      <List
        size="small"
        dataSource={data}
        loading={isLoading}
        pagination={{
          pageSize: 10,
        }}
        rowKey="id"
        renderItem={(item: any) => (
          <>
            <List.Item
              title={item.name}
              actions={[
                <Tooltip title="Delete">
                  <Button
                    icon={<MinusOutlined />}
                    size="small"
                    shape="circle"
                    onClick={() => deleteBranch(item.id)}
                    disabled={!checkPermit('branch-delete')}
                  />
                </Tooltip>,
              ]}
            >
              <div className="permissionlists" style={{ padding: '10px' }}>
                <Text
                  editable={
                    !checkPermit('branch-delete')
                      ? false
                      : {
                          tooltip: <div>Edit {item.name} Branch</div>,
                          onChange: (value) =>
                            item.name !== value ? updateBranch(value, item.id) : {},
                        }
                  }
                >
                  {item.name}
                </Text>
              </div>
            </List.Item>
          </>
        )}
      />
    );
  };

  const uploadProps = {
    name: 'file',
    accept: ExcelFiles,
    showUploadList: false,
    customRequest: (file: any) => onFileUpload(file.file),
    beforeUpload: (file: File) => {
      const isValidType = ExcelFiles.includes(file.type);
      if (!isValidType) {
        message.error(`${file.name} is not a Valid Excel File`);
      }
      return isValidType ? true : Upload.LIST_IGNORE;
    },
  };

  const ActionButtons = () => {
    return (
      <Space>
        <Tooltip title="Add new Branch">
          <Button
            icon={<PlusCircleOutlined />}
            size="small"
            onClick={() => setAddMode(true)}
            disabled={!checkPermit('branch-add')}
          />
        </Tooltip>
        <Tooltip title="Import Branches">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Upload {...uploadProps}>
            <Button
              icon={<CloudUploadOutlined />}
              size="small"
              disabled={!checkPermit('branch-import')}
            >
              Import From File
            </Button>
          </Upload>
        </Tooltip>
        <RefreshButton onClick={refetch} />
      </Space>
    );
  };

  return (
    <Page metaDescriptionContent="Branch" title="Branch">
      <Title level={5}> Branch </Title>
      <hr color="white" />
      <ActionButtons />
      <BranchList />
      <AddBranchModel isVisible={isAddMode} setVisible={() => setAddMode(false)} />
    </Page>
  );
};

export { Branch };
